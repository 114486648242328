import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Alert, Button, Checkbox, Divider, Form, Input, message } from 'antd';
import { INVITE_ACCOUNT_KEY, PasswordNotVisibleIcon, PasswordVisibleIcon, UserRole, UserStatus, WarningIcon, firstPage } from '@/constants';
import { loginUser } from '@/redux/slice/user';
import { isEmail, rsaEncrypt } from '@/utils/util';
import { isString } from '@/utils/typeHelpers';
import { REMEMBER_ACCOUNT_KEY } from '@/constants';
import { EMAIL_REGEXP } from '@/constants';
import { ErrorCode } from '@/constants';
import { TRIGGER_TYPES } from '@/constants';
import { login, LoginResult, thirdPartyLogin } from '@/services/login';
import styles from './Login.module.scss';
import { setAuthCookies } from '@/utils/cookie';
import { setAuth } from '@/utils/request';
import { addGtagEvent, gaEventName } from '@/utils/ga';
import useUser from '@/hooks/useUser';
import { comeFrom } from '@/pages/login';
import { MyAES } from '@/utils/aes';
import { INVITE_COOPERATION_EMAIL, INVITE_COOPERATION_ID, INVITE_KOL_EMAIL, INVITE_KOL_ID } from '../invite/constants';
import { InfoIcon } from '../koc/payment/Icons';
import ThirdPartyAuthButtons, { SupportThirdPartyAuthPlatform, ThirdPartyAuthInfo } from './ThirdPartyAuthButtons';

export const LoginRoleList = [UserRole.KOC_ADVERTISER, UserRole.KOC];

const intlMessgaes = defineMessages({
  loginTitle: { id: 'login.title', defaultMessage: 'Log in' },
  loginSubtitle: {
    id: 'login.subtitle',
    defaultMessage: 'No account? <link>Sign up</link>.',
  },
  email: { id: 'login.email', defaultMessage: 'Email' },
  emailPlaceholder: {
    id: 'login.emailPlaceholder',
    defaultMessage: 'Please enter email',
  },
  emailRequiredError: {
    id: 'login.emailRequiredError',
    defaultMessage: 'Please enter email',
  },
  emailInvalidError: {
    id: 'login.emailInvalidError',
    defaultMessage: 'Invalid email format',
  },
  password: { id: 'login.password', defaultMessage: 'Password' },
  passwordPlaceholder: {
    id: 'login.passwordPlaceholder',
    defaultMessage: '6-12 characters, number(s) + letter(s)',
  },
  emailOrPasswordError: {
    id: 'login.emailOrPasswordError',
    defaultMessage: 'The email and password do not match',
  },
  rememberPassword: {
    id: 'login.rememberPassword',
    defaultMessage: 'Remember password',
  },
  forgetPassword: {
    id: 'login.forgetPassword',
    defaultMessage: 'Forget password',
  },
  submit: { id: 'login.submit', defaultMessage: 'Log in' },
  roleDeny: { id: 'login.roleDeny', defaultMessage: 'insufficient authority' },
  noCompanyInfo: {
    id: 'login.noCompanyInfo',
    defaultMessage: "Please enter your<link> company's info </link> to activate your account.",
  },
});
const Login: React.FC<{ query: Record<string, any> }> = ({ query }) => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const [emailOrPasswordError, setEmailOrPasswordError] = useState(false);
  const [noCompanyInfoError, setNoCompanyInfoError] = useState(false);
  const password = Form.useWatch('password', form);
  const { email: user_email } = useUser();
  const { utm_ydk: kol_id, utm_yde: kol_email } = query;
  const from = comeFrom(kol_id, kol_email);
  useEffect(() => {
    form.resetFields();
  }, [locale]);

  useEffect(() => {
    const { query } = router;
    if (query.f === 'ivth') {
      form.setFieldsValue({
        email: sessionStorage.getItem(INVITE_ACCOUNT_KEY),
      });
      sessionStorage.removeItem(INVITE_ACCOUNT_KEY);
    }
    if (isString(query.email)) {
      form.setFieldsValue({
        email: query.email,
      });
    }
  }, [router]);

  const formatError = () => {
    if (emailOrPasswordError) setEmailOrPasswordError(false);
    if (noCompanyInfoError) setNoCompanyInfoError(false);
  };

  const handleFinish = ({ email, remember }: { email: string; remember: boolean }) => {
    addGtagEvent({
      event: gaEventName.CLICK,
      options: {
        click_id: 'login_btn_click',
        user_email,
        from,
        kol_id:
          sessionStorage.getItem(INVITE_COOPERATION_ID) ||
          sessionStorage.getItem(INVITE_KOL_ID) ||
          (Number(MyAES.decrypt(String(kol_id))) && MyAES.decrypt(String(kol_id))),
        kol_email:
          sessionStorage.getItem(INVITE_COOPERATION_EMAIL) ||
          sessionStorage.getItem(INVITE_KOL_EMAIL) ||
          (kol_email && MyAES.decrypt(String(kol_email))),
      },
    });
    if (remember) return localStorage.setItem(REMEMBER_ACCOUNT_KEY, email);
    localStorage.removeItem(REMEMBER_ACCOUNT_KEY);
  };

  const handleLogin = async (loginService: () => Promise<LoginResult>, email?: string) => {
    setLoading(true);
    try {
      const res = await loginService();
      if (!LoginRoleList.includes(Number(res.userType))) {
        return message.error(formatMessage(intlMessgaes.roleDeny));
      }
      dispatch(loginUser(res));
      setAuthCookies(res);
      setAuth(res.userId, res.authorization);

      const isKOC_ADVERTISER = Number(res.userType) === UserRole.KOC_ADVERTISER;
      const invitation = sessionStorage.getItem('invitationFirstPage');
      if (invitation) {
        router.push('/koc/orders');
        sessionStorage.removeItem('invitationFirstPage');
        return;
      }

      if (isKOC_ADVERTISER && res.status === UserStatus.REGISTERED) {
        router.push('/company-info');
        return;
      }

      router.push(firstPage[res.userType]);
    } catch (error: any) {
      if (!email) return; // 如果是第三方授权登录，不需要跳转到邮件激活页面
      const { code } = error;
      switch (code) {
        case ErrorCode.INACTIVE_KOL:
          router.push({
            pathname: '/register/validation',
            query: {
              email,
              identity: 'KOL',
              sendEmail: Date.now(),
            },
          });
          break;
        case ErrorCode.INACTIVE_ADVERTISER:
          router.push({
            pathname: '/register/validation',
            query: {
              email,
              identity: 'ADVERTISER',
              sendEmail: Date.now(),
            },
          });
          break;
        default:
          setEmailOrPasswordError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const loginValidator = async (_: any, value: any) => {
    if (!value) return;
    const { email, password } = form.getFieldsValue();
    handleLogin(login.bind(null, email, await rsaEncrypt(password)), email);
  };

  const handleGo2ResetPsw = () => {
    const email = form.getFieldValue('email');
    if (isString(email) && isEmail(email)) {
      router.push({
        pathname: '/password-reset',
        query: { email, from: '/login' },
      });
    } else {
      router.push('/password-reset');
    }
  };

  const emailOrPasswordErrorRender = emailOrPasswordError && (
    <Alert message={formatMessage(intlMessgaes.emailOrPasswordError)} type="error" showIcon icon={<WarningIcon />} />
  );

  const noCompanyInfoErrorRender = noCompanyInfoError && (
    <Alert
      message={
        <div>
          {formatMessage(intlMessgaes.noCompanyInfo, {
            link: (text: any) => <Link href="/company-info">{text}</Link>,
          })}
        </div>
      }
      type="info"
    />
  );

  //#region --------- 第三方授权注册 ---------
  const handleMessage = async (authInfo: ThirdPartyAuthInfo, platform: SupportThirdPartyAuthPlatform) => {
    // 从第三方授权页面传递过来的信息
    const { userId, email, userType } = authInfo;
    if (!email) {
      // 无法获取到邮箱时报错提示（使用 facebook 授权时可能无法获取到邮箱）
      message.error({
        content: formatMessage({ id: 'thirdPartyAuth.getEmailFail', defaultMessage: '无法获取您的邮箱' }),
        key: 'getEmailFail',
      });
      // 跳转到注册页，不携带参数
      router.replace('/register');
    } else {
      message.info({
        content: formatMessage({ id: 'thirdPartyAuth.authenticated', defaultMessage: `${email}已授权` }, { email }),
        icon: <InfoIcon />,
        key: 'authenticated',
      });
      addGtagEvent({
        event: gaEventName.THIRD_PARTY_AUTH_SUCCESS,
        options: {
          email,
          user_id: userId,
          platform,
          page_location: window.location.href,
          page_title: '登录页',
        },
      });

      const userInfo = {
        userId: await rsaEncrypt(userId.toString()),
        email: await rsaEncrypt(email),
      };

      if (userType === 0) {
        // 如果未注册则前往选择用户身份
        router.replace('/register?userInfo=' + encodeURIComponent(JSON.stringify(userInfo)));
      } else {
        // 如果已注册则直接登录
        handleLogin(thirdPartyLogin.bind(null, userInfo));
      }
    }
  };
  //#endregion

  return (
    <div className={styles.container}>
      <div className={styles.title}> {formatMessage(intlMessgaes.loginTitle)}</div>
      <div className={styles.register}>
        {formatMessage(intlMessgaes.loginSubtitle, {
          link: (text: any) => <Link href="/register">{text}</Link>,
        })}
      </div>

      <ThirdPartyAuthButtons handleMessage={handleMessage} />

      <Form
        className={styles.form}
        form={form}
        name="login"
        layout="vertical"
        initialValues={{
          remember: false,
          email: typeof window !== 'undefined' ? localStorage.getItem(REMEMBER_ACCOUNT_KEY) : '',
        }}
        onFinish={handleFinish}
        requiredMark={false}
      >
        <Form.Item
          name="email"
          label={formatMessage(intlMessgaes.email)}
          validateFirst
          rules={[
            {
              required: true,
              message: formatMessage(intlMessgaes.emailRequiredError),
              validateTrigger: TRIGGER_TYPES.onBlur,
            },
            {
              pattern: EMAIL_REGEXP,
              message: formatMessage(intlMessgaes.emailInvalidError),
              validateTrigger: TRIGGER_TYPES.onSubmit,
            },
          ]}
          validateTrigger={[TRIGGER_TYPES.onSubmit, TRIGGER_TYPES.onBlur]}
        >
          <Input allowClear placeholder={formatMessage(intlMessgaes.emailPlaceholder)} onChange={formatError} />
        </Form.Item>
        <Form.Item
          name="password"
          style={{ marginBottom: 28 }}
          label={
            <div className={styles.passwordLabel}>
              <span>{formatMessage(intlMessgaes.password)}</span>
              <a onClick={handleGo2ResetPsw}>{formatMessage(intlMessgaes.forgetPassword)}</a>
            </div>
          }
          rules={[
            {
              required: true,
              message: formatMessage(intlMessgaes.emailRequiredError),
              validateTrigger: 'onSubmit',
            },
            {
              validator: loginValidator,
              validateTrigger: 'onSubmit',
            },
          ]}
          validateTrigger={['onSubmit', 'onBlur']}
        >
          <Input.Password
            allowClear
            onChange={formatError}
            placeholder={formatMessage(intlMessgaes.passwordPlaceholder)}
            iconRender={(visible) => (
              <div style={{ display: 'flex' }}>
                {!visible ? (
                  <span className={styles.passwordIcon}>
                    <PasswordNotVisibleIcon />
                  </span>
                ) : (
                  <span className={styles.passwordIcon}>
                    <PasswordVisibleIcon />
                  </span>
                )}
              </div>
            )}
          />
        </Form.Item>
        <Form.Item className={styles.rememberFormItem} name="remember" valuePropName="checked">
          <Checkbox className={styles.remember}>
            <span className="select-none">{formatMessage(intlMessgaes.rememberPassword)}</span>
          </Checkbox>
        </Form.Item>
        {emailOrPasswordErrorRender}
        {noCompanyInfoErrorRender}
        <Button type="primary" htmlType="submit" className={styles.loginBtn} loading={loading} disabled={!password}>
          {formatMessage(intlMessgaes.loginTitle)}
        </Button>
      </Form>

      <div className={styles.policy}>
        <a target="_blank" rel="noopener noreferrer" href={`https://www.youdaoads.com/user-terms?hl=${locale}`}>
          <FormattedMessage id="common.userTerms" defaultMessage="User Terms" />
        </a>
        <Divider type="vertical" />
        <a target="_blank" rel="noopener noreferrer" href={`https://www.youdaoads.com/a/private-policy?hl=${locale}`}>
          <FormattedMessage id="common.privatePolicy" defaultMessage="Privacy Policy" />
        </a>
      </div>
    </div>
  );
};

export default Login;
